import * as CONSTANTS from '../modules/constants';

export function stickyHeader() {
    window.addEventListener('load', function () {
        const header = document.getElementById('siteHeader');
        const nav = document.getElementById('siteNav');
        const main = document.getElementById('siteMain');
        const siteBody = document.body;
        const mobileSearch = document.querySelector('.mobile-nav-search-container-js') as HTMLElement;

        let headerDimensions = header.offsetHeight - nav.offsetHeight;
        let lastScrollTop = 0;

        function adjustMainContentLocation() {           
            if (window.matchMedia('(max-width: 1025px)').matches) {
                main.style.marginTop = (header.offsetHeight - mobileSearch.offsetHeight).toString() + 'px';
            }
            else {
                main.style.marginTop = header.offsetHeight.toString() + 'px';
            }
        }

        adjustMainContentLocation();

        //function initMobileSticky() {
        //    // Handle header compensation on smaller screens
        //    if (window.matchMedia('(max-width: 768px)').matches) {
        //        headerDimensions = header.offsetHeight - nav.offsetHeight;
        //    }
        //}

        //initMobileSticky();

        function resetOffsets() {
            headerDimensions = header.offsetHeight - nav.offsetHeight;
        }

        function stickyDisplayHandler() {
            // only run on larger screens
            if (window.matchMedia('(min-width: 1026px)').matches) {
                var currentPos = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"

                headerDimensions = header.offsetHeight - nav.offsetHeight;

                // handle header sticky
                if (currentPos === 0) {
                    if (!CONSTANTS.GLOBAL_BODY.classList.contains('locked')) {
                        header.classList.add('sticky-show');
                        header.style.top = '0px';
                        header.classList.remove('sticky-top');
                    }
                } else if (currentPos > lastScrollTop && currentPos > headerDimensions) {
                    if (!CONSTANTS.GLOBAL_BODY.classList.contains('locked')) {
                        // downscroll code
                        header.classList.add('sticky-top');
                        header.classList.remove('sticky-show');
                        header.style.top = '-' + headerDimensions + 'px';
                    }
                } else if (currentPos < lastScrollTop && currentPos > headerDimensions) {
                    if (!CONSTANTS.GLOBAL_BODY.classList.contains('locked')) {
                        // upscroll code
                        header.classList.add('sticky-show');
                        header.style.top = '0px';
                    }
                }

                // set current position to lastScrollTop
                lastScrollTop = currentPos;
            }
        }

        window.addEventListener('resize', () =>
            window.requestAnimationFrame(() => {
                //initMobileSticky();
                stickyDisplayHandler();
                adjustMainContentLocation();
            })
        );

        window.addEventListener('scroll', () =>
            window.requestAnimationFrame(() => {
                stickyDisplayHandler();
            })
        );
    });
}