export class Search {
    constructor() {
        let search = document.querySelector('.util-nav-search-js');
        let searchContainer = document.querySelector('.nav-search-container-js');
        let mobileSearch = document.querySelector('.mobile-util-nav-search-js');
        let mobileSearchContainer = document.querySelector('.mobile-nav-search-container-js');
        
        search.addEventListener('click', function () { openSearchContainer(searchContainer) });
        mobileSearch.addEventListener('click', function () { openSearchContainer(mobileSearchContainer) });

        function openSearchContainer(container) {
            if (container.classList.contains('nav-search-container-open')) {
                container.classList.remove('nav-search-container-open');
            }
            else {
                container.classList.add('nav-search-container-open');
                container.querySelector('.nav-search-textbox-js').focus();
            }  
        }
    }
}