import * as _ from 'lodash';

function wrapAll(node: HTMLElement, container: HTMLElement, inner: HTMLElement) {
    // Cache the current parent and previous sibling of the node.
    var parent = node.parentNode;
    var previousSibling = node.previousSibling;

    // Place node in wrapper.
    inner.appendChild(node);

    // Place the wrapper just after the cached previousSibling,
    // or if that is null, just before the first child.
    var nextSibling = previousSibling ? previousSibling.nextSibling : parent.firstChild;
    parent.insertBefore(container, nextSibling);

    return container;
}

export default function () {
    let richTextNodes = document.getElementsByClassName('adage-wysiwyg-inner');

    _.forEach(richTextNodes, richText => {
        let childNodes = richText.childNodes;
        let tableNodes = _.filter(childNodes, childNode => childNode.nodeName === 'TABLE');

        if (tableNodes.length > 0) {
            let tableNodesArray = Array.prototype.slice.call(tableNodes);

            _.forEach(tableNodesArray, tableNode => {
                let containerDiv = document.createElement('div');
                let innerDiv = document.createElement('div');
                let tableDiv = document.createElement('div');

                containerDiv.classList.add('adage-wysiwyg-table-container');
                innerDiv.classList.add('adage-wysiwyg-table-inner');
                tableDiv.classList.add('adage-wysiwyg-table');

                innerDiv.appendChild(tableDiv);
                containerDiv.appendChild(innerDiv);

                wrapAll(tableNode, containerDiv, tableDiv);
            })
        }
    });
}