import Awesomplete = require('awesomplete');
import axios from 'axios';
import { ChangeEvent } from 'react';

let autocompleteElement = document.getElementById("autocomplete-networks-js") as HTMLInputElement;
let stateDropdown = document.getElementById("state-options-js") as HTMLSelectElement;
let findLink: HTMLAnchorElement = document.getElementById("find-a-network-js") as HTMLAnchorElement;

// This is the query value when the user searches by state
const stateQueryKey = "selectedState";
const networkQueryKey = "selectedNetwork";
let selectedState = "";

// Keep the pathname in a local variable since this will change
const completeUrl = window.location.pathname.split('?');
let internalUrl = completeUrl[0];
let queryUrl = completeUrl[1];

if (stateDropdown && autocompleteElement) {
    stateDropdown.addEventListener("change", evt => {
        const updatedUrl = `${internalUrl}?${networkQueryKey}=${autocompleteElement.value}&${stateQueryKey}=${stateDropdown.value}`;

        findLink.href = updatedUrl;
    })

    autocompleteElement.addEventListener("keyup", (evt: any) => {
        const updatedUrl = `${internalUrl}?${networkQueryKey}=${evt.currentTarget.value}&${stateQueryKey}=${stateDropdown.value}`;
        findLink.href = updatedUrl;

        if (evt.keyCode === 13) {
            findLink.click();
        }
    });

    autocompleteElement.addEventListener("awesomplete-select", (evt: any) => {
        autocompleteElement.value = evt.text.value;
        const updatedUrl = `${internalUrl}?${networkQueryKey}=${autocompleteElement.value}&${stateQueryKey}=${stateDropdown.value}`;
        findLink.href = updatedUrl;
    });

    axios.get('/Umbraco/Api/FindNetworkApi/GetNetworks').then(response => {
        let data = response.data.map(selectItem => { return { label: selectItem.Text, value: selectItem.Value } });

        const autocomplete = new Awesomplete(autocompleteElement, { list: data });

    });
}

// Scroll users to the search button / results region after they've selected a network or state
if (location.href.indexOf('selectedNetwork') !== -1) {
    let findLinkLocation = findLink.getBoundingClientRect().bottom;

    window.scrollTo(0, findLinkLocation);
}
