import '../helpers/closestPolyfill';

export class MobileNav {
    constructor() {
        let mobileNavTrigger = document.querySelector('.mobile-nav-trigger-js');
        let mobileNavDropDown = document.querySelector('.mobile-nav-dropdown-js');
        let mobileNavInnerTrigger = document.querySelectorAll('.mobile-nav-trigger-btn-js');
        let mobileNavInnerTriggers = Array.prototype.slice.call(mobileNavInnerTrigger);
        let body = document.querySelector('body');

        mobileNavTrigger.addEventListener('click', function () {
            if (mobileNavTrigger.classList.contains('mobile-nav-trigger-open')) {
                mobileNavTrigger.classList.remove('mobile-nav-trigger-open');
                mobileNavDropDown.classList.remove('mobile-nav-dropdown-open');
                body.classList.remove('mobile-nav-open');
            }
            else {
                mobileNavTrigger.classList.add('mobile-nav-trigger-open');
                mobileNavDropDown.classList.add('mobile-nav-dropdown-open');
                body.classList.add('mobile-nav-open');
            }
        })

        mobileNavInnerTriggers.forEach(function (item) {
            item.addEventListener('click', function () {
                let triggerContainer = item.closest('.mobile-nav-link-dropdown-container-js'); 
                let dropdown = triggerContainer.querySelector('.mobile-nav-link-dropdown-js');

                if (dropdown.classList.contains('mobile-nav-link-dropdown-open')) {
                    dropdown.classList.remove('mobile-nav-link-dropdown-open');   
                    item.querySelector('.mobile-nav-link-trigger-btn').classList.remove('mobile-nav-link-trigger-btn-open');
                }   
                else {
                    dropdown.classList.add('mobile-nav-link-dropdown-open');  
                    item.querySelector('.mobile-nav-link-trigger-btn').classList.add('mobile-nav-link-trigger-btn-open');
                }
            })
        })      
    }
}