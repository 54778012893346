import { getIEVersion } from '../helpers/getIEVersion';
import { setTimeout } from 'timers';

export class FullBleedImage {
    imgTag: HTMLImageElement;

    constructor(imageEl: HTMLImageElement) {
        this.imgTag = imageEl as HTMLImageElement;
        if (getIEVersion() > 0) {
            this.replaceImg();
        }
    }

    replaceImg() {
        let imgReplacement = document.createElement('figure');
        imgReplacement.style.backgroundImage = `url('${this.imgTag.src}')`;
        console.log(this.imgTag.src);
        if (this.imgTag.alt.length > 0) {
            let altReplacement = document.createElement('p');
            altReplacement.className = 'visually-hidden';
            altReplacement.innerHTML = this.imgTag.alt;
            imgReplacement.appendChild(altReplacement);
        }
        this.imgTag.parentNode.replaceChild(imgReplacement, this.imgTag);
        setTimeout(() => {
            imgReplacement.className = this.imgTag.className;
        }, 300);
    }
}