import { Accordion } from '../modules/accordion';
import { Gallery } from '../modules/gallery';
import { FullBleedImage } from '../modules/fullBleedImagesIE';
import { nodeListToArray } from '../helpers/nodeListToArray';
import { SvgSprite } from '../modules/svg-loader';
import { Modal } from '../modules/modal';
import { MobileNav } from '../modules/mobile-navigation';
import { stickyHeader } from '../modules/sticky-header';
import { Search } from '../modules/search';
import 'objectfitPolyfill';
import * as A11yDialog from 'a11y-dialog';
import { LoginModal } from '../modules/login-modal';
import { alertMessage } from '../modules/alert-message';
import wrapTable from '../modules/wrap-tables';
import '../modules/find-a-network';

(() => {
    // Accordion Init
    let accordions = new Accordion({
        oneOpenAtATime: true
    });

    // Fix images in IE that must be full-bleed
    let fullBleedImages = nodeListToArray(document.querySelectorAll('[data-full-bleed-image] img'));
    fullBleedImages.forEach(imageEl => {
        new FullBleedImage(imageEl);
    })

    // Gallery Init
    // Note: it is recommended to use loop: false when creating a gallery with videos at the beginning/end because of duplicated slides.
    let galleryClass = 'single-item-gallery';
    let galleries = nodeListToArray(document.querySelectorAll('[data-gallery]'));
    galleries.forEach((gallery, index) => {
        gallery.classList.add(`${galleryClass}-${index}`);
        new Gallery({
            containerClass: `${galleryClass}-${index}`,
            slidesPerView: 1
        })
    });

    //// Video Modals Init
    //let modals = nodeListToArray(document.querySelectorAll('[data-modal]'));
    //modals.forEach(modal => {
    //    new Modal(modal);  
    //})

    // Mobile Navigation
    new MobileNav();

    // SVG Sprite Inject
    new SvgSprite('/Static/img/svg-sprite.svg');

    // Sticker Header
    stickyHeader();

    // Search button logic
    new Search();

    // Login Modal
    new LoginModal();

    // Alert Message
    alertMessage();

    // Wraps table elements found in WYSIWYG content with
    // divs we can style to prevent overflow issues on mobile
    wrapTable();


})();

