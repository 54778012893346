import * as CONSTANTS from '../modules/constants';
import 'isomorphic-fetch';

export function alertMessage() {
    const alertMessageClass = '.alert-js';
    const alertCancelId = '.alert-close-js';
    const showClass = 'alert-show';
    const main = document.getElementById('siteMain');
    const header = document.getElementById('siteHeader');
    const mobileSearch = document.querySelector('.mobile-nav-search-container-js') as HTMLElement;

    let alertMessageElements = Array.prototype.slice.call(document.querySelectorAll(alertMessageClass));
    let alertCancelElements = Array.prototype.slice.call(document.querySelectorAll(alertCancelId));

    if (alertMessageElements != null && alertCancelElements != null) {
        window.addEventListener('DOMContentLoaded', setAlertMessageVisibility);

        alertCancelElements.forEach(element => {
            element.addEventListener('click', cancelAlert);
        })
    }

    function calcSiteHeaderHeight() {    
        if (window.matchMedia('(max-width: 1025px)').matches) {
            main.style.marginTop = (header.offsetHeight - mobileSearch.offsetHeight).toString() + 'px';
        }
        else {
            main.style.marginTop = header.offsetHeight.toString() + 'px';
        }

    }

    function setAlertMessageVisibility() {
        fetch('/Umbraco/Api/SiteWideAlertMessageApi/ShouldShowMessage',
        {
            credentials: 'include',
        })
        .then(response => {
            if (response.ok) {
                return response.json() as Promise<boolean>;
            }
            else {
                // Failure case
                console.log(response);
            }
        }).then(shouldShowMessage => {
            if (shouldShowMessage) {
                alertMessageElements.forEach(element => {
                    element.classList.add(showClass);
                });
                calcSiteHeaderHeight();
            }
        });
    }

    function cancelAlert() {
        fetch('/Umbraco/Api/SiteWideAlertMessageApi/CloseMessage', {
            method: 'post',
            credentials: 'include',
        })
        .then(response => {
            if (response.ok) {
                alertMessageElements.forEach(element => {
                    element.classList.remove(showClass);
                });
                calcSiteHeaderHeight();
                return response;
            }
            else {
                // Failure case
                console.log(response);
            }
        });
    }
}
