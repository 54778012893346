import * as A11yDialog from 'a11y-dialog';

export class LoginModal {
    constructor() {

        let loginModal = document.getElementById('loginModal');

        if (loginModal) {
            const loginDialog = new A11yDialog(loginModal);
            let requireLoginLinks = Array.prototype.slice.call(document.querySelectorAll('a[data-require-login]'));

            requireLoginLinks.forEach(link => {
                if (link.getAttribute('data-require-login') === 'True') {
                    link.addEventListener('click', function (e) {
                        e.preventDefault();
                        loginDialog.show();
                    });
                }
            });

            if (loginModal.getAttribute('data-show-on-load') === 'True') {
                loginDialog.show();
            }


            loginDialog.on('show', () => {
                document.body.style.overflow = 'hidden';
            });
            loginDialog.on('hide', () => {
                document.body.style.overflow = '';
            });
        }
    }
}